import { Configuration } from "@azure/msal-browser";

export const tokenScopes = [`${process.env["SSO_TOKEN_SCOPES"]}`] ?? ["Missing TOKEN_SCOPES"];

export const msalConfiguration: Configuration = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: process.env["SSO_CLIENT_ID"] ?? "Missing CLIENT_ID",
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority: `https://login.microsoftonline.com/${process.env["SSO_TENANT_ID"] ?? "Missing SSO_TENANT"}`,
    // Full redirect URL, in form of http://localhost:8080/auth-callback
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
