import React from "react";

interface BannerProps {
  environment: string;
}

const Banner: React.FC<BannerProps> = ({ environment }) => (
  <div className="banner">
    <p>{environment}</p>
  </div>
);

const EnvironmentBanner: React.FC = () => {
  const subdomain = window.location.hostname.split(".")[0];

  switch (subdomain) {
    case "fixa-admin":
      return null;
    case "fixa-admin-stage" || "fixa-admin-dev":
      return <Banner environment="DEMO" />;
    case "fixa-admin-dev":
      return <Banner environment="DEVELOPMENT" />;
    case "localhost":
      return <Banner environment="LOCALHOST - KEEP ON TRUCKIN'" />;
    default:
      return null;
  }
};

export default EnvironmentBanner;
